import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { Alert, Container, Typography, Grid, styled, IconButton, Button, Chip } from '@mui/material';
import { CompareRounded } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';

import PageHeader from 'src/components/PageHeader';
import { getAllDataLoadsHistory } from 'src/api/index.js';
import { useAppContext } from 'src/AppContext.js';
import EmptyState from 'src/components/EmptyState';
import { ReactComponent as EmptyStateSvg } from 'src/images/EmptyStateCompareArrows.svg'
import { useQuery } from 'src/hooks';
import LoadError from 'src/components/LoadError';
import CollapsibleTable from 'src/components/CollapsibleTable';
import ErrorFileDownload from 'src/components/DataLoadsStatus/ErrorFileDownload';
import matchColumns from './dataGridColumns';


function MatchesFile() {
  const { carrierConfig } = useAppContext();
  const [searchParams] = useSearchParams();
  const [stateRefreshCounter, setStateRefreshCounter] = useState(0);
  const [open, setOpen] = useState(true);
  const [isDownloadError, setIsDownloadError] = useState(false);
  const [matchResults, setMatchResults] = useState([]);

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [cutoffTime, tz] = DateTime.fromISO('2023-09-25T12:00:00Z', { zone: 'utc' })
    .setZone(timeZone)
    .toFormat('ha ZZZZ').split(' ');

  const deliveryTime = DateTime.fromISO('2023-09-25T15:00:00Z', { zone: 'utc' })
    .setZone(timeZone)
    .toFormat('ha');

  const setup = searchParams.get('setup');

  function handleDownloadError() {
    setIsDownloadError(true);
  }

  function clearDownloadError() {
    setIsDownloadError(false);
  }

  const { data = [], isLoading, isError } = useQuery(['matchFileDataLoads', stateRefreshCounter], () => getAllDataLoadsHistory(carrierConfig.carrierId, 'match-files'));

  const GridWrapper = styled(Grid)(
    () => `
  
  @media screen and (max-width: 768px) {
    .MuiDataGrid-cell {
    min-width: 150px !important;
    }
    .MuiDataGrid-columnHeader {
      min-width: 150px !important;
    }
  }
  `
  )

  const AlertWrapper = styled(Container)(
    () => `
    width: 100%;
    max-width: 100% !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 0px !important;
    margin-right: 25px !important;
    position: fixed;
    z-index: 999999;
    top: 70px;
      .completed-alert {
        width: calc(100% - 340px);
        border-radius: 4px;
      }
      .alert-close {
        color: #fff;
        font-size: 13px;
      }
      .close-icon {
        margin-left: 3px;
        font-size: 20px;
      }
      @media screen and (max-width: 1279px) {
        .completed-alert {
          width: calc(100% - 50px);
          border-radius: 4px;
        }
      }
      `
  );

  function emptyStateText() {
    return (
      <>If you haven’t already submitted your files, get started.<br />
        Check back again later for file status.
      </>
    )
  }

  function setClosed() {
    setOpen(false);
  }

  function reload() {
    const counter = (stateRefreshCounter + 1);
    setStateRefreshCounter(counter);
  }

  useEffect(() => {
    if (data?.length > 0) {
      let arrayMap = data.map((item) => {
        const container = {};
        container.status = item?.fileStatus;
        container.variant = 'match results table';
        container.id = item?.id;
        container.fileName = item?.fileName;
        container.matchNickname = item?.nickname;
        container.completed = item?.fileStatus === 'Skipped' ? '-' : item?.completed;
        container.note = item?.note
        const colors  = {
          'Processing': 'info',
          'Failed': 'error',
          'Errors Found': 'warning',
          'Successful': 'success',
          'Skipped': 'default'
        };
        const color = colors[item?.fileStatus] || '';
        container.fileStatus = <Chip size="small" color={color} className={item?.fileStatus} label={item?.fileStatus} variant="outlined" />
        const displayDownload = parseInt(item?.numOfErrors) > 0 || item?.fileStatus === 'Failed';
        if (displayDownload) {
          container.downloadFile = <ErrorFileDownload paddingTop="10px" height="40px" handleDownloadError={handleDownloadError} fileType='match-files' dataLoadId={item?.id} />
        }
        container.totalMatches = item?.fileStatus === 'Skipped' ? '-' : item?.totalMatches;
        container.newMatches = item?.newMatches;
        container.deletedMatches = item?.deletedMatches;
        container.otherMatches = item?.updatedMatches;

        return container;
      })
      setMatchResults(arrayMap);
    }
  },[data]) 

  return (
    <>
      {setup === "completed" && open && !isLoading ? <AlertWrapper>
        <Alert
          className="completed-alert"
          variant="filled"
          severity="success"
          action={
            <IconButton className="alert-close"
              aria-label="close"
              color="inherit"
              size="small"
              onClick={setClosed}
            >
              CLOSE <CloseIcon className='close-icon' fontSize="inherit" />
            </IconButton>
          }>File Setup Complete<br />
          Start using LENS by sending us your Customer File. </Alert>
      </AlertWrapper> : ''}
      <PageHeader title="Match Results" subtitle="All recent and historical Match Results." icon={<CompareRounded />} />
      {(isError) && <LoadError reload={reload} />}
      {(!isError) &&
        <GridWrapper>
          {(!isLoading && !isError && data?.length === 0) ?
            <EmptyState headerText='Were ready to find matches.' bodyText={emptyStateText()} icon={<EmptyStateSvg />} /> :
            <>
              <Typography className="hide" variant="h5" sx={{ mb: 3 }}>Recent Matches</Typography>
              <Typography variant="h5" sx={{ mb: 2 }}>Match Results from Submitted Lists</Typography>
              <Typography variant="body1" sx={{ mb: 4 }}>Submissions before {`${cutoffTime.toLowerCase()} ${tz}`} are processed in matching that day M-F. Results returned are based on your configurations. For daily or one time deliveries, match results appear the following day {`${deliveryTime.toLowerCase()} ${tz}`}. To request an alternate delivery time, please contact your Evadata representative.</Typography>
              <Alert className={isDownloadError ? 'show' : 'hide'} sx={{ mb: 2, mr: 3 }} onClick={clearDownloadError} severity="error">An error was encountered when retrieving the file, try downloading it again.</Alert>
              <CollapsibleTable columns={matchColumns} data={matchResults} message="No lists to display." usePagination isLoading={isLoading}/>
              <Grid container sx={{mt: 3}}>
                <Grid item xs={12} sm={9}>
                  <Typography variant="subtitle2" color="text.primary" sx={{ mb: 3 }}>Match Results Notification
                    <Typography variant="body2" color="text.primary" sx={{ mb: 3 }}>Once the file status is successful, locate the match results from your organization’s transfer location. </Typography>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} sx={{textAlign: {sm: 'right', xs: 'left'}}}>
                  <Button LinkComponent={Link} to="/transfer-locations"  variant="text">View Transfer Locations</Button>
                </Grid>
              </Grid>
            </>
          }
        </GridWrapper>
      }
    </>
  )
}

MatchesFile.propTypes = {};

export default MatchesFile;